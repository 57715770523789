<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="5" :sm="15">
            <a-form-item label="流程定义key">
              <a-input placeholder="请输入" v-model="queryParam.processDefKey" allowClear />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="query">查询</a-button>
<!--              <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>-->
              <a-button style="margin-left: 8px" @click="reset">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button
        :disabled="selectedRowKeys.length === 0"
        type="primary"
        icon="check"
        @click="handelAuditBatch(selectedRowKeys, 2)"
        >批量同意</a-button
      >
      <a-button :disabled="selectedRowKeys.length === 0" icon="close" @click="handelAuditBatch(selectedRowKeys, 3)"
        >批量驳回</a-button
      >
    </div>

    <!-- 数据表格 -->
    <a-table
      :loading="tableLoading"
      :row-selection="rowSelection"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="page"
      :rowClassName="rowClassName"
      @change="changeTable"
    >
      <span slot="name" slot-scope="text">
        <a-tooltip>
          <template slot="title"> {{ text }} </template> {{ text }}
        </a-tooltip>
      </span>
      <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
      <span slot="suspended" slot-scope="text">
        <a-badge :status="text ? 'error' : 'processing'" :text="text ? '挂起' : '激活'" />
      </span>
      <span slot="action" slot-scope="text, record">
        <!-- <a-divider v-if="editEnabel" type="vertical" />
        <a v-if="editEnabel" @click="handleEdit(record.id)">编辑</a> -->
        <a @click="handelAudit(record)" :disabled="record.suspended">审批</a>
        <a-divider type="vertical" />
        <a @click="handFlow(record.procInstId)">历史</a>
      </span>
    </a-table>

    <!--    <leave-modal ref="leavemodal" @ok="handleOk"/>-->
    <!--    <purchase-modal ref="purchasemodal" @ok="handleOk"/>-->
    <proc-flow-modal ref="modal" @ok="handleOk" />

    <a-modal title="审批" width="40%" :visible="visible" :footer="null" @cancel="visible = false" :maskClosable="false">
      <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
        <a-textarea placeholder="审批备注" v-model="rejectReason"></a-textarea>
      </a-form-model>
      <div class="footer-bts">
        <a-button type="default" @click="visible = false">取消</a-button>
        <a-button type="danger"  :loading="loading"  @click="onAudit(3)">驳回</a-button>
        <a-button type="primary" :loading="loading"  @click="onAudit(2)">同意</a-button>
      </div>
    </a-modal>

    <a-modal :title="title" width="40%" :visible="batchVisible" :footer="null" @cancel="batchVisible = false" :maskClosable="false">
      <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
        <a-textarea placeholder="审批备注" v-model="batchRejectReason"></a-textarea>
      </a-form-model>
      <div class="footer-bts">
        <a-button type="default" @click="batchVisible = false">取消</a-button>
        <a-button type="primary" :loading="batchLoading"  @click="onBatchAudit()">确定</a-button>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getTasking, auditTaskBatch, auditTask,modalMap } from '@/api/activiti'
// import LeaveModal from './modules/LeaveModal.vue'
// import PurchaseModal from './modules/PurchaseModal.vue'
import ProcFlowModal from './modules/ProcFlowModal.vue'
export default {
  name: 'Tasking',
  components: {
    STable,
    // LeaveModal,
    ProcFlowModal,
    // PurchaseModal
  },
  data() {
    return {
      title:'批量驳回审批',
      taskId: '',
      rejectReason:'',
      batchRejectReason: '',
      status:0,
      loading:false,
      batchLoading:false,
      tableData: [],
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      tableLoading: false, //表格loading
      visible: false,
      batchVisible: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      form: this.$form.createForm(this),
      mdl: {},
      permissions: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'index' },
        },
        {
          title: '流程标题',
          dataIndex: 'processName',
        },
        {
          title: '所属流程',
          dataIndex: 'processDefName',
        },
        {
          title: '节点名称',
          dataIndex: 'name',
        },
        {
          title: '申请人',
          dataIndex: 'applyer',
        },
        {
          title: '状态',
          dataIndex: 'suspended',
          scopedSlots: { customRender: 'suspended' },
        },
        {
          title: '操作',
          width: '150px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   return getTasking(Object.assign(parameter, this.queryParam))
      // },
      previewVisible: false,
      previewImage: '',
      selectedRowKeys: [],
      selectedRows: [],
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.suspended, // Column configuration not to be checked
          },
        }),
      }
    },
  },
  filters: {},
  methods: {
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleOk() {
      this.getData()
      console.log('handleSaveOk')
    },
    handFlow(procInstId) {
      this.$refs.modal.show(procInstId)
    },
    handelAudit(record) {
      this.visible = true;
      this.taskId = record.id
      //const modal = modalMap[record.processDefKey]
      //this.$refs[modal].show(record)
    },
    handelAuditBatch(taskIds, result) {
      const _that = this
      if(result == 2){
        _that.title = '批量同意审批'
      }
      _that.batchVisible = true;
      _that.taskIds = taskIds
      _that.result = result
      // _that.$confirm({
      //   title: '确认审核',
      //   content: '确认要审核选择的申请吗?',
      //   onOk() {
      //     auditTaskBatch({ taskIds: taskIds, result: result }).then((res) => {
      //       if (res.code === 200) {
      //         _that.$message.success('审核成功')
      //         _that.handleOk()
      //       } else {
      //         _that.$message.error(res.msg)
      //       }
      //     })
      //   },
      //   onCancel() {},
      // })
    },

    // 审核
    onAudit(result) {
      const _that = this
      _that.loading = true
      const query = {
        taskId: _that.taskId,
        result: result,
        comment: _that.rejectReason
      }
      auditTask(query).then((res) => {
        if (res.code === 200) {
          _that.$message.success('审核成功')
          _that.handleOk()
        } else {
          _that.$message.error(res.msg)
        }
        _that.taskId = ''
        _that.rejectReason = ''
        _that.visible = false
        _that.loading = false
      })
    },

    onBatchAudit() {
      const _that = this
      _that.batchLoading = true
      auditTaskBatch({ taskIds: _that.taskIds, result: _that.result ,comment: _that.batchRejectReason }).then((res) => {
        if (res.code === 200) {
          _that.$message.success('审核成功')
          _that.handleOk()
        } else {
          _that.$message.error(res.msg)
        }
        _that.taskIds = ''
        _that.batchRejectReason = ''
        _that.batchVisible = false
        _that.batchLoading = false
      })
    },


    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    // 查询
    query(){
      this.page.current = 1;
      this.getData();
    },
    // 重置
    reset(){
      this.queryParam = {}
      this.page.current = 1;
      this.page.pageSize = 10;
      this.getData()
    },

    getData() {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.tableLoading = true
      return getTasking({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.queryParam,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, rows } = res
            this.page.total = total
            this.tableData = rows
          }
        })
        .finally(() => (this.tableLoading = false))
    },
  },
  watch: {
    /*
      'selectedRows': function (selectedRows) {
        this.needTotalList = this.needTotalList.map(item => {
          return {
            ...item,
            total: selectedRows.reduce( (sum, val) => {
              return sum + val[item.dataIndex]
            }, 0)
          }
        })
      }
      */
  },
  created() {
    this.getData()
  },
}
</script>
<style lang="less" scoped>
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #E9E9E9;
}
</style>